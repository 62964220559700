<template>
  <div class="row m-0 align-items-center">
    <div class="col-auto px-1 cursor">
      <SearchItem name="Search" size="small" align="center" :bg="backgroundColor" @click="goTo('search')">
        <IconSearch2 color="gold" />
      </SearchItem>
    </div>
    <div class="col-auto px-1 relative" @mouseover="isHover = true" @mouseout="isHover = false" v-if="user">
      <IconRound name="Profile" size="small" align="center" :bg="backgroundColor" class="cursor" @click="goTo('profile/0')">
        <IconUserProfile color="gold" size="size28" />
      </IconRound>
      <ProfilePopup :user="user" v-show="isHover"/>
    </div>
    <div class="col-auto px-1 relative" v-else>
      <IconRound name="Log-In" size="small" align="center" :bg="backgroundColor" class="cursor" @click="isLogin = true">
        <IconUserProfile color="gold" size="size28" />
      </IconRound>
    </div>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" :redirect="true"/>
    </transition>
    <div class="col-auto px-1 relative" @mouseover="isHoverCart = true" @mouseout="isHoverCart = false">
      <IconRound name="Cart" size="small"  align="right" :bg="backgroundColor" class="cursor">
        <IconCart color="gold" size="size28" />
      </IconRound>
        <NotificationBadge />
      <CartPopup v-show="isHoverCart" />
    </div>
    <SwitchLocation :backgroundColor="backgroundColor"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue')),
    SearchItem: defineAsyncComponent(() => import('@/components/SearchItem.vue')),
    NotificationBadge: defineAsyncComponent(() => import('@/components/NotificationBadge.vue')),
    ProfilePopup: defineAsyncComponent(() => import('@/views/ProfilePopup.vue')),
    CartPopup: defineAsyncComponent(() => import('@/views/CartPopup.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    IconCart: defineAsyncComponent(() => import('@/components/icons/IconCart.vue')),
    IconUserProfile: defineAsyncComponent(() => import('@/components/icons/IconUserProfile.vue')),
    IconSearch2: defineAsyncComponent(() => import('@/components/icons/IconSearch2.vue')),
    SwitchLocation: defineAsyncComponent(() => import('@/components/location/SwitchLocation.vue'))
  },
  name: 'TopMenuIconLinks',
  data () {
    return {
      isHover: false,
      isHoverCart: false,
      isLogin: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    backgroundColor () {
      var bg
      if (this.$route.name === 'Home') {
        bg = 'rgba(0, 0, 0, 0.38)'
      } else {
        bg = null
      }
      return bg
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>
